<template>
    <div>
        <b-row class="mt-2">
            <b-col v-if="!disabled" class="col-sm-12 col-md-4">
                <b-form-group
                    :label="i18nAnswer"
                    class="required label-pdv"
                    label-for="answer"
                    :invalid-feedback="invalidFeedback"
                >
                    <b-form-input
                        v-model.trim="$v.questionChoices.answer.$model"
                        name="answer"
                        class="input-pdv-blue"
                        type="text"
                        autocomplete="off"
                        :state="validateField('answer', 'questionChoices')"
                        @input="clearResponseError('answer', 'questionChoices')"
                        @blur="$v.questionChoices.answer.$touch"
                    />
                </b-form-group>
                <b-form-group
                    :label="i18nAdditionalType"
                    label-for="itemType"
                    class="label-pdv"
                >
                    <b-form-select
                        v-model.trim="questionChoices.type"
                        name="itemType"
                        class="input-pdv-blue"
                        :options="additionalTypes"
                    ></b-form-select>
                </b-form-group>
                <add-button @onClick="addItem" />
            </b-col>
            <b-col :class="disabled ? 'col-sm-12 col-md-12' : 'col-sm-12 col-md-8'">
                <div class="table-responsive table-pdv">
                    <b-table-simple
                        hover
                        show-empty
                        sticky-header
                    >
                        <b-thead>
                            <b-th v-if="!disabled"></b-th>
                            <b-th>{{ i18nAnswer }}</b-th>
                            <b-th>{{ i18nAdditionalType }}</b-th>
                            <b-th v-if="!disabled"></b-th>
                        </b-thead>
                        <draggable
                            v-model="choices"
                            :class="{ [`table-cursor-grabbing`]: dragging === true }"
                            group="choices" tag="tbody"
                            handle=".table-handle"
                            @start="dragging = true"
                            @end="dragging = false"
                        >
                            <b-tr v-for="(choice, index) in choices" :key="choice.id">
                                <b-td v-if="!disabled">
                                    <div class="table-handle">
                                        <i class="fe-move"/>
                                    </div>
                                </b-td>
                                <b-td>
                                    {{choice.label}}
                                </b-td>
                                <b-td>
                                    {{typeToText(choice.additional_type)}}
                                </b-td>
                                <b-td v-if="!disabled" class="d-flex justify-content-end align-items-center">
                                    <delete-table-button
                                        v-if="!disabled && choices.length > 0"
                                        @onClick="removeItem(index)"
                                    />
                                </b-td>
                            </b-tr>
                        </draggable>
                    </b-table-simple>
                    </div>
            </b-col>
        </b-row>
        <b-form-invalid-feedback id="itemTextFeedBack">
            {{ listInvalidFeedback }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import draggable from 'vuedraggable'
import AddButton from '@/src/components/AddButton'
import DeleteTableButton from '@/src/components/DeleteTableButton.vue'
import swalFeedback from '@src/mixins/swalFeedback'
import answerTypes from '@src/mixins/answerTypes'
import validationMixin from '@src/mixins/validation'

export default {
    components: {
        draggable,
        AddButton,
        DeleteTableButton,
    },
    mixins: [
        swalFeedback,
        answerTypes,
        validationMixin,
    ],
    props: {
        value: {
            type: Array,
            required: true,
        },
        additionalTypeDisabled: {
            type: Boolean,
            default: false,
        },
        invalidFeedback: {
            type: String,
            default: '',
        },
        listClass: {
            type: String,
            default: '',
        },
        listInvalidFeedback: {
            type: String,
            default: '',
        },
        removeItem: {
            type: Function,
            default: function (index) {
                this.choices.splice(index, 1)
            },
        },
        disabled: {
            type: Boolean,
            required: false,
        },
    },
    validations: {
        questionChoices: {
            answer: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(255),
                itemDoesntExist(value) {
                    return (
                        this.choices.findIndex((choice) => choice.label === value) === -1
                    )
                },
            },
        },
    },
    data() {
        return {
            dragging: false,
            questionChoices: {
                answer: '',
                type: null
            },
        }
    },
    computed: {
        choices: {
            deep: true,
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        i18nAdditionalType() {
            return this.getI18n('COMMON', 'additional_type')
        },
        i18nAnswer() {
            return this.getI18n('COMMON', 'answer')
        },
    },
    methods: {
        addItem() {
            this.$v.questionChoices.$touch()
            if (this.$v.questionChoices.$invalid) {
                return
            }

            this.choices.push({
                label: this.questionChoices.answer,
                additional_type: this.questionChoices.type
            })

            Object.assign(this.questionChoices, {
                answer: '',
                type: null,
            })

            this.$v.questionChoices.$reset()
        },
    },
}
</script>
