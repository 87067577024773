<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
        :handle-hide="cleanModal"
        :handle-open="resetValidation"
        :v="$v.modalQuestion"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nQuestion"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nQuestion"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'QUESTION')"
                                :title="getI18n(i18nKey, 'TITLES.question')"
                                :busy="editLoading"
                                :disabled="deleteLoading"
                                @onClick="handleEdit"
                            />
                           <delete-label-button
                                v-if="$can('DELETE', 'QUESTION')"
                                :title="getI18n(i18nKey, 'TITLES.question')"
                                :busy="deleteLoading"
                                :disabled="editLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col class="d-flex justify-content-center" md="12">
                        <p v-if="isEdit && modalQuestion.hasAnswers" class="text-danger">
                            {{ getI18n(i18nKey, 'ERRORS.question_has_answers') }}
                        </p>
                    </b-col>
                </b-row>
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nQuestion"
                            class="required label-pdv"
                            label-for="modal-question"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_question')"
                        >
                            <b-form-input
                                id="modal-question"
                                v-model.trim="$v.modalQuestion.question.$model"
                                class="input-pdv-blue"
                                name="question"
                                type="text"
                                :state="validateField('question', 'modalQuestion')"
                                :disabled="!isEdit || submitLoading || (isQuestionCreated && modalQuestion.hasAnswers)"
                                autofocus
                                autocomplete="off"
                                @input="clearResponseError('question', 'modalQuestion')"
                                @blur="$v.modalQuestion.question.$touch"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nType"
                            class="required label-pdv"
                            label-for="modal-type"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_type')"
                        >
                            <translated-multiselect
                                id="modal-type"
                                v-model="typeSelected"
                                :options="types"
                                :disabled="!isEdit || submitLoading || isQuestionCreated"
                                :select-class="validationClass($v.modalQuestion.type)"
                                @input="changeType"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="Object.keys(subtypes).includes(modalQuestion.type)" md="4">
                        <b-form-group
                            :label="i18nSubtype"
                            class="required label-pdv"
                            label-for="modal-subtype"
                        >
                            <translated-multiselect
                                id="modal-subtype"
                                v-model="subtypeSelected"
                                :options="subtypes[modalQuestion.type]"
                                :disabled="!isEdit || submitLoading || isQuestionCreated"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col v-if="modalQuestion.type === 'choice'" md="2">
                        <b-form-group
                            :label="i18nMultipleAnswers"
                            class="label-pdv"
                            label-for="modal-multiple-answers"
                        >
                            <b-form-checkbox
                                id="modal-multiple-answers"
                                v-model="modalQuestion.is_multiple"
                                name="is-multiple"
                                switch
                                size="lg"
                                :disabled="!isEdit || submitLoading || modalQuestion.type !== 'choice'"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row v-if="modalQuestion.type === 'boolean'">
                    <b-col md="4">
                        <b-form-group
                            :label="i18nAdditionalTypeTrue"
                            class="required label-pdv"
                            label-for="modal-additional-type-true"
                        >
                            <b-form-select
                                v-model="modalQuestion.additional_type_true"
                                class="input-pdv-blue"
                                :disabled="!isEdit || submitLoading || modalQuestion.hasAnswers"
                                :options="additionalTypes"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group
                            :label="i18nAdditionalTypeFalse"
                            class="required label-pdv"
                            label-for="modal-additional-type-false"
                        >
                            <b-form-select
                                v-model="modalQuestion.additional_type_false"
                                class="input-pdv-blue"
                                :disabled="!isEdit || submitLoading || modalQuestion.hasAnswers"
                                :options="additionalTypes"
                            ></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row v-if="modalQuestion.type === 'choice'">
                    <b-col md="12">
                        <questionChoices
                            ref="questionChoices"
                            v-model="modalQuestion.choices"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_choice')"
                            :list-class="choiceListClass"
                            :list-invalid-feedback="invalidChoiceListFeedback"
                            :additional-type-disabled="modalQuestion.hasAnswers"
                            :disabled="!isEdit || submitLoading"
                        />
                    </b-col>
                </b-form-row>
                <b-form-row v-if="modalQuestion.type === 'number'">
                    <b-col md="4">
                        <b-form-group
                            :label="i18nPrecision"
                            class="required label-pdv"
                            label-for="precision"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_precision')"
                        >
                            <multi-select
                                id="precision"
                                v-model="precisionSelected"
                                :options="range"
                                label="label"
                                :searchable="false"
                                :disabled="!isEdit || submitLoading"
                                @input="changePrecision"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <template v-if="modalQuestion.type === 'file'">
                    <b-form-row>
                        <b-col md="4">
                            <b-form-group
                                :label="i18nMin"
                                class="required label-pdv"
                                label-for="modal-min-file"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_min_files')"
                            >
                                <b-form-input
                                    id="modal-min-file"
                                    v-model.number="modalQuestion.min_files"
                                    class="input-pdv-blue"
                                    name="min-file"
                                    aria-describedby="input-1-live-feedback"
                                    type="number"
                                    :state="validateField('min_files', 'modalQuestion')"
                                    :placeholder="i18nMin"
                                    :disabled="!isEdit || submitLoading"
                                    @blur="$v.modalQuestion.min_files.$touch"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                                :label="i18nMax"
                                class="required label-pdv"
                                label-for="modal-max-file"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_max_files')"
                            >
                                <b-form-input
                                    id="modal-max-file"
                                    v-model.number="modalQuestion.max_files"
                                    class="input-pdv-blue"
                                    name="max-file"
                                    aria-describedby="input-1-live-feedback"
                                    type="number"
                                    :state="validateField('max_files', 'modalQuestion')"
                                    :placeholder="i18nMax"
                                    :disabled="!isEdit || submitLoading"
                                    @blur="$v.modalQuestion.max_files.$touch"
                                />
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </template>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isQuestionCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@components/FormModal'
import {
    required,
    requiredIf,
    minLength,
    maxLength,
    between,
    numeric,
} from 'vuelidate/lib/validators'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import multiSelect from '@components/multiSelect'
import translatedMultiselect from '@src/components/translatedMultiselect'
import QuestionsService from '@src/services/QuestionsService.js'
import questionChoices from '@src/router/views/questions/questionChoices'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import answerTypes from '@src/mixins/answerTypes'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONS'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        multiSelect,
        translatedMultiselect,
        questionChoices,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
    },
    mixins: [validationMixin, swalFeedback, answerTypes],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    name: '',
                    is_multiple: false,
                    type: null,
                    precision: 0,
                    choices: [],
                    additional_type_true: null,
                    additional_type_false: null,
                    min_files: 1,
                    max_files: 20,
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalQuestion: this.modal,
            loadingProduct: true,
            isEdit: false,
            editLoading: false,
            submitLoading: false,
            deleteLoading: false,
            products: [],
            typeSelected: null,
            subtypeSelected: null,
            precisionSelected: 0,
            range: [
                {
                    id: 0,
                    label: '1',
                },
                {
                    id: 1,
                    label: '1,0',
                },
                {
                    id: 2,
                    label: '1,00',
                },
                {
                    id: 3,
                    label: '1,000',
                },
            ],
        }
    },
    validations() {
        const validations = {
            modalQuestion: {
                question: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(255),
                },

                type: {
                    required,
                    inTypes(value) {
                        const acceptedTypes = this.types.map(
                            (type) => type.value
                        )
                        return (
                            acceptedTypes.findIndex((type) => type === value) >
                            -1
                        )
                    },
                },

                precision: {
                    required: requiredIf(function (modalQuestion) {
                        return modalQuestion.type === 'number'
                    }),
                    numeric,
                    between: between(0, 3),
                },
                choices: {},
                min_files: {},
                max_files: {},
            },
        }
        if (this.modalQuestion.type === 'file') {
            validations.modalQuestion.min_files = { between: between(1, 20) }
            validations.modalQuestion.max_files = {
                between: between(1, 20),
                isGreaterOrEqualThanMinFile(value, model) {
                    if (model.type === 'file') {
                        return value >= model.min_files
                    }
                },
            }
        }
        if (this.modalQuestion.type === 'choice') {
            validations.modalQuestion.choices = {
                minLength: function (value) {
                    return value.length >= 2
                },
            }
        }
        return validations
    },

    computed: {
        isQuestionCreated() {
            return this.modalQuestion.id > 0
        },
        i18nTitleModal() {
            if (this.isQuestionCreated) {
                return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nQuestion}`
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_question')
            }
        },
        invalidChoiceListFeedback() {
            return this.getI18nModifiedWithNumber({
                prefix: 'ERROR_CODES',
                suffix: 'insufficient_choices',
                number: 2,
            })
        },
        choiceListClass() {
            if (!this.$v.modalQuestion.choices.$dirty) {
                return ''
            }
            return this.$v.modalQuestion.choices.$invalid
                ? 'is-invalid'
                : 'is-valid'
        },
        i18nPrecision() {
            return this.getI18n(i18nKey, 'FIELDS.precision')
        },
        i18nMultipleAnswers() {
            return this.getI18n(i18nKey, 'TITLES.multiple_answers')
        },
        i18nQuestion() {
            return this.getI18n(i18nKey, 'TITLES.question')
        },
        i18nType() {
            return this.getI18n(i18nKey, 'FIELDS.type')
        },
        i18nSubtype() {
            return this.getI18n(i18nKey, 'FIELDS.subtype')
        },
        i18nMin() {
            return `${this.i18nFileQuantity} - ${this.getI18n(i18nCommon, 'minimum')} (1)`
        },
        i18nMax() {
            return `${this.i18nFileQuantity} - ${this.getI18n(i18nCommon, 'maximum')} (20)`
        },
        i18nFileQuantity() {
            return this.getI18n(i18nKey, 'TITLES.file_quantity')
        },
        i18nQuestions() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.question',
                modifier: 2,
            })
        },
        modalOperation() {
            return this.isQuestionCreated ? 'edited' : 'created'
        },

        i18nChoice() {
            return this.getI18n(i18nKey, 'FIELDS.choice')
        },

        i18nAdditionalTypeTrue() {
            return this.getI18n('COMMON', 'additional_type_true')
        },

        i18nAdditionalTypeFalse() {
            return this.getI18n('COMMON', 'additional_type_false')
        },
    },

    watch: {
        subtypeSelected(newValue, oldValue) {
            this.modalQuestion.subtype = newValue ? newValue.value : null
        },
    },
    methods: {
        changeType(option) {
            this.subtypeSelected = null
            this.modalQuestion.type = option ? option.value : null
            if (!option || option.value !== 'choice') {
                this.modalQuestion.is_multiple = false;
            }
            this.$v.modalQuestion.type.$touch()
        },
        changePrecision(option) {
            this.modalQuestion.precision = option.id
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.editLoading = false
            this.submitLoading = false
            this.deleteLoading = false
            this.typeSelected = this.types.find((t) => t.value === this.modal.type) || null
            if (this.subtypes[this.modal.type]) {
                this.subtypeSelected = this.subtypes[this.modal.type].find((t) => t.value === this.modal.subtype)
            }
            this.precisionSelected = this.range.find((precision) => precision.id === this.modal.precision) || 0
            this.modalQuestion = this.modal
            this.modalQuestion.hasAnswers = false
            this.$nextTick(() => this.$refs.formModal.show())
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        cleanModal() {
            this.replaceModalData({
                id: '',
                question: '',
                is_multiple: false,
                type: '',
                choices: [],
                precision: 0,
                additional_type_true: null,
                additional_type_false: null,
                min_files: 1,
                max_files: 20,
            })
            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalQuestion, data)
        },

        async findIfQuestionHasAnswer() {
            await QuestionsService.fetchById(this.modalQuestion.id, {
                check_for_answers: true,
            }).then((response) => {
                this.modalQuestion.hasAnswers = response.data.data.hasAnswers
            })
        },
        async handleEdit() {
            this.editLoading = true
            await this.findIfQuestionHasAnswer();
            this.isEdit = true
            this.editLoading = false
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            if (this.modalQuestion.type === 'choice' && this.modalQuestion.choices.length <= 1) {
                this.$refs.questionChoices.$v.questionChoices.$touch()
                this.warningFeedback(this.i18nQuestion, this.getI18n(i18nKey, 'ERRORS.question_choice_two_answers'))
                return;
            }

            this.$v.modalQuestion.$touch()
            if (!this.$v.modalQuestion.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const operation = this.isQuestionCreated
                ? QuestionsService.update(
                      this.modalQuestion.id,
                      this.modalQuestion
                  )
                : QuestionsService.create(this.modalQuestion)

            const createResponse = await operation
                .then((response) => response)
                .catch((error) => {
                    this.unsuccessfulOperation()
                    return error
                })

            const statusCode = createResponse.status.toString()

            if (statusCode.charAt(0) === '2') {
                if (this.modalQuestion.type === 'choice') {
                    const choicesResponse = await QuestionsService.syncChoices(
                        createResponse.data.data.id,
                        this.modalQuestion.choices
                    )
                        .then((response) => response)
                        .catch((error) => {
                            return error
                        })

                    const choicesStatusCode = choicesResponse.status.toString()

                    if (choicesStatusCode.charAt(0) === '2') {
                        this.successfulOperation()
                    }

                    if (choicesStatusCode === '417') {
                        const suffix = `ERRORS.${choicesResponse.data.message}`
                        const operation = this.isQuestionCreated ? 'update' : 'create'
                        this.operationUnsuccessful(i18nKey, suffix, operation)
                        this.$refs.formModal.hide()
                    }
                } else {
                    this.successfulOperation()
                }
            }

            if (statusCode === '422') {
                this.modalResponseErrors = createResponse.data.errors
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteQuestion)
        },
        async deleteQuestion(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.$v.modalQuestion.$touch()
            this.deleteLoading = true

            const response = await QuestionsService.delete(this.modalQuestion.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nQuestion, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },

        successfulOperation() {
            this.$emit('refresh')
            this.resetValidation()
            this.$refs.formModal.hide()
            this.positiveFeedback(this.i18nQuestion, this.modalOperation, 2)
        },

        unsuccessfulOperation() {
            this.negativeFeedback()
        },

    },
}
</script>
