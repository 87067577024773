<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import QuestionsService from '@src/services/QuestionsService.js'
import questionsModal from '@src/router/views/questions/questionsModal'
import questionFilterModal from '@views/questions/questionFilterModal'
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@components/PageHeader'
import FilterBar from '@src/components/FilterBar'
import pagination from '@src/mixins/pagination'
import swalFeedback from '@src/mixins/swalFeedback'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import filterVuetable from '@src/mixins/filterVuetable'
import answerTypes from '@src/mixins/answerTypes'
import AddButton from '@/src/components/AddLabelButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'QUESTIONS'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        Breadcrumb,
        PageHeader,
        questionsModal,
        questionFilterModal,
        FilterBar,
        AddButton,
        FilterLabelButton,
        MoreTableButton
    },
    mixins: [
        swalFeedback,
        pagination,
        vuetableFormatters,
        filterVuetable,
        answerTypes,
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'question',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            additionalParameters: {
                with: ['choices'],
            },
            modalIsEdit: false,
            modalQuestion: {
                id: '',
                question: '',
                is_multiple: false,
                choices: [],
                precision: 0,
                type: '',
                has_answers: null,
            },
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'question',
                    title: this.getI18n(i18nKey, 'TITLES.question'),
                    sortField: 'question',
                },
                {
                    name: 'type',
                    title: this.getI18n(i18nKey, 'FIELDS.type'),
                    sortField: 'type',
                    formatter: this.typeToText,
                },
                {
                    name: 'subtype',
                    title: this.getI18n(i18nKey, 'FIELDS.subtype'),
                    sortField: 'subtype',
                    formatter: this.subtypeToText,
                },
                {
                    name: 'is_multiple',
                    title: this.getI18n(i18nKey, 'TITLES.multiple_answers'),
                    sortField: 'is_multiple',
                    formatter: this.booleanIcons,
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            css: {
                pagination: {
                    activeClass: 'btn-primary',
                    pageClass: 'btn btn-rounded',
                    linkClass: 'btn text-black',
                    icons: {
                        prev: '',
                        next: '',
                    },
                },
            },
            filterText: '',
            filters: {
                type: '',
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'WORKSCHEDULES',
                        suffix: 'TITLES.work_schedule',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.question',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nType() {
            return this.getI18n(i18nCommon, 'type')
        },
        i18nNewQuestion() {
            return this.getI18n(i18nKey, 'BUTTONS.new_question')
        },
    },

    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                search: '',
                with: ['choices'],
            }
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `question:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },

    methods: {
        createAdditionalParameters() {
            this.additionalParameters.search = ''

            const search = this.createSearch({
                type: this.filters.type ? this.filters.type.value : null,
            })

            return this.formatParameters({
                with: ['choices'],
                search,
                searchJoin: search.search(';') > 0 ? 'and' : '',
            })
        },
        fetch(url, params) {
            return QuestionsService.fetchVuetable(url, params)
        },
        i18nQuestion(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.question',
                modifier: modifier,
            })
        },
        cleanModal() {
            this.replaceModalData({
                id: '',
                choices: [],
                is_multiple: false,
                question: '',
                type: '',
                precision: 0,
                has_answers: null,
            })
        },
        showModal() {
            this.$nextTick(() => this.$refs.questionsModal.showModal())
        },
        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },
        async openModalToEdit(question) {
            this.modalIsEdit = false
            this.replaceModalData(question)
            this.showModal()
        },
        replaceModalData(data) {
            Object.assign(this.modalQuestion, data)
        },
        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },
        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.questionFilterModal.showModal(this.filters)
            )
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText" />
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-button
                            v-if="$can('CREATE', 'QUESTION')"
                            :title="i18nNewQuestion"
                            @onClick="openModalToCreate"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nQuestion(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
            <questions-modal
                ref="questionsModal"
                :modal="modalQuestion"
                :modal-is-edit="modalIsEdit"
                @refresh="reloadTable"
            />
            <question-filter-modal ref="questionFilterModal" :handle-submit="filter" />
        </div>
    </Layout>
</template>
